import React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section } from '../components/Section'
import BgImg from '../images/brand-list.jpg'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import BreakpointDown from "../components/Media/BreakpointDown"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"

const SectionAfterBg = styled(Section)`
min-height:100vh;
display:flex;
align-items:center;
.container{
  position:relative;
  z-index:2;
}
 &:after{
   width:100%;
   background-size:cover;
 }
`
const TitleAfter = styled.div`
 position:fixed;
 bottom: -31px;
 left:0;
 right:0;
 font-size:214px;
 font-weight:900;
 z-index:1;
 text-transform:uppercase;
 opacity:0.1;
 color:#000;
 font-family: "Nunito Sans", sans-serif;
 text-align:center;
 line-height:1;
 @media(max-width:1440px){
   font-size:180px;
 }
 ${BreakpointDown.xl`
       font-size: 143px;
  `}
   ${BreakpointDown.xl`
       font-size: 100px;
       bottom: -17px;
  `}
  ${BreakpointDown.md`
     display:none;
  `}
`
const NotFound = styled.div`
  max-width:400px;
  margin-left:auto;
  margin-right:auto;
  position: relative;
  text-align:center;
  ${BreakpointDown.md`
     max-width:320px;
    `}
`
const Content = styled.div`
h1{ margin-top:15px}
a{color:#529A00; font-weight:600}
`



const ThankYouPage = () => (
  <Layout pageName="thank-you">
    <SEO title="Thank You" />
    <SectionAfterBg bgAfter={`url(${BgImg})`} pt="200px" pb="200px" xpt="150px" xpb="150px">
      <TitleAfter>Thank you!</TitleAfter>
      <div className="container">
        <NotFound>
          <StaticImage
            src="../svg/tick.svg"
            alt="Thank you"
            placeholder="blurred"
          />
          <Content>
            <h1 className="h2">Thank You!</h1>
            <p>Our team will be in contact with you shortly...</p>
            <Link to="/"><PrimaryLinkButton text="Go To Home Page" /></Link>
          </Content>
        </NotFound>
      </div>
    </SectionAfterBg>
  </Layout>
)

export default ThankYouPage
